import { Container, Row, Col, Form as BsForm, Button, Spinner } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import InputBox from '../components/InputBox'
import axios from 'axios'
import { Link, useHistory, useParams } from 'react-router-dom'

const PasswordReset = () => {
  const { token } = useParams()
  const history = useHistory()

  const initialValues = {
    newpassword: '',
    confirmnewpassword: '',
  }
  // validation
  const validationSchema = Yup.object().shape({
    newpassword: Yup.string().required().min(3).max(20).label('Password'),
    confirmnewpassword: Yup.string()
      .oneOf([Yup.ref('newpassword'), null], 'Both password need to be the same')
      .required()
      .label('Confirm password'),
  })
  // This submit function send and token and new password and change the password
  const onSubmit = async (values, helpers) => {
    await axios({
      method: 'POST',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/reset-password',
      data: {
        token: token,
        newPassword: values.newpassword,
      },
    })
      .then((response) => {
        helpers.resetForm()
        history.push('/')
        toast.success('You password has been changed successfully.', {
          duration: 10000,
        })
      })
      .catch((error) => {
        toast.error(error.response.data.error)
      })
  }

  return (
    <Container>
      <Row>
        <Col xl={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={12} xs={12}>
          <div className='mb-5 text-center'>
            <h5>Change Password</h5>
          </div>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <BsForm.Group controlId='newpassword'>
                  <InputBox autoComplete='off' type='password' label='New Password' name='newpassword' tabIndex={1} />
                </BsForm.Group>
                <BsForm.Group controlId='confirmnewpassword'>
                  <InputBox
                    autoComplete='off'
                    type='password'
                    label='Confirm New Password'
                    name='confirmnewpassword'
                    tabIndex={2}
                  />
                </BsForm.Group>
                <BsForm.Group>
                  <Button type='submit' block tabIndex={3} disabled={!(dirty && isValid) || isSubmitting}>
                    {isSubmitting ? <Spinner animation='border' size='sm' /> : 'Reset your password'}
                  </Button>
                </BsForm.Group>
              </Form>
            )}
          </Formik>
          <div className='form-group text-center'>
            <span className='text-muted'>Don't want to change your password?</span> <Link to='/'>Login</Link>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default PasswordReset
