import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap'
import { Form as BsForm } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

import InputBox from '../components/InputBox'
import toast from 'react-hot-toast'
import { Link } from 'react-router-dom'

const Registration = () => {
  const history = useHistory()

  const [Countries, setCountries] = useState([])
  const [Loading, setLoading] = useState(true)

  useEffect(() => {
    async function getAllCountries() {
      await axios({
        method: 'GET',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/countries',
      })
        .then((response) => {
          setLoading(false)
          setCountries(response.data)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
        })
    }
    // this network request Checks if user has Access token or not. if your has token then redirect to the main page.
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/authcheck',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(() => {
        setLoading(false)
        window.location = '/instruction'
      })
      .catch((error) => {
        setLoading(false)
        localStorage.clear()
        console.log(error)
      })
      .finally(() => {
        setLoading(false)
        getAllCountries()
      })

    return () => {}
  }, [history])

  const initialValues = {
    email: '',
    name: '',
    country: '',
    companyName: '',
    companyWebsite: '',
    phone: '',
    telephone: '',
    password: '',
    confirmPassword: '',
  }
  // validation for user registration
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
    name: Yup.string().required().label('Name'),
    country: Yup.string().required().label('Country'),
    companyName: Yup.string().required().label('Company Name'),
    companyWebsite: Yup.string().required().label('Company Website URL'),
    phone: Yup.string().min(10).max(10).label('Phone Number'),
    telephone: Yup.string().required().min(10).label('Telephone Number'),
    password: Yup.string().required().min(3).max(20).label('Password'),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .label('Confirm Password'),
  })
  // this submit function for register the user and send data to backend and store in the database and user receive and activation email in the registered email address
  const onSubmit = async (values, helpers) => {
    // check if user provide phone number or not
    if (values.phone === '') {
      await axios({
        method: 'POST',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/signup',
        data: {
          email: values.email,
          name: values.name,
          countryId: values.country,
          companyName: values.companyName,
          companyWebsite: values.companyWebsite,
          telephone: values.telephone,
          password: values.password,
        },
      })
        .then((response) => {
          helpers.resetForm()
          toast.success("We'll send you a verification email. Open it up to activate your account.")
        })
        .catch((error) => {
          toast.error(error.response.data.error)
        })
    } else {
      await axios({
        method: 'POST',
        baseURL: process.env.REACT_APP_API_URL,
        url: '/signup',
        data: {
          email: values.email,
          name: values.name,
          countryId: values.country,
          companyName: values.companyName,
          companyWebsite: values.companyWebsite,
          phone: values.phone,
          telephone: values.telephone,
          password: values.password,
        },
      })
        .then((response) => {
          helpers.resetForm()
          toast.success("We'll send you a verification email. Open it up to activate your account.")
        })
        .catch((error) => {
          toast.error(error.response.data.Error)
        })
    }
  }

  return (
    <Container>
      <Row>
        <Col xl={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} sm={12} xs={12}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ isSubmitting, isValid, dirty, setFieldValue }) => (
              <Form>
                <BsForm.Group controlId='email'>
                  <InputBox autoComplete='off' type='email' label='Email address *' name='email' tabIndex={1} />
                </BsForm.Group>
                <BsForm.Group controlId='name'>
                  <InputBox autoComplete='off' type='text' label='Name *' name='name' tabIndex={2} />
                </BsForm.Group>
                <BsForm.Group controlId='country'>
                  <InputBox
                    as='select'
                    custom
                    label='List of countries *'
                    name='country'
                    value={undefined}
                    disabled={Loading}
                    onChange={(e) => {
                      setFieldValue('country', e.target.value)
                    }}
                    tabIndex={3}
                  >
                    <option label={Loading ? 'Loading...' : 'Select a country'} defaultValue />
                    {Countries.map((country) => (
                      <option key={country.id} value={country.id} label={country.name} />
                    ))}
                  </InputBox>
                </BsForm.Group>
                <Row>
                  <Col md={6} sm={12} xs={12}>
                    <BsForm.Group controlId='companyName'>
                      <InputBox autoComplete='off' type='text' label='Company name *' name='companyName' tabIndex={4} />
                    </BsForm.Group>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <BsForm.Group controlId='companyWebsite'>
                      <InputBox
                        autoComplete='off'
                        type='text'
                        label='Company Website URL *'
                        name='companyWebsite'
                        placeholder='e.g. domain.com'
                        tabIndex={4}
                      />
                    </BsForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12} xs={12}>
                    <BsForm.Group controlId='phone'>
                      <InputBox
                        autoComplete='off'
                        type='tel'
                        label='Phone number'
                        name='phone'
                        placeholder='e.g. +91-12345-67890'
                        tabIndex={5}
                      />
                    </BsForm.Group>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <BsForm.Group controlId='telephone'>
                      <InputBox
                        autoComplete='off'
                        type='tel'
                        label='Telephone number *'
                        name='telephone'
                        placeholder='e.g. 022-21242124'
                        tabIndex={6}
                      />
                    </BsForm.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12} xs={12}>
                    <BsForm.Group controlId='password'>
                      <InputBox autoComplete='off' type='password' label='Password *' name='password' tabIndex={7} />
                    </BsForm.Group>
                  </Col>
                  <Col md={6} sm={12} xs={12}>
                    <BsForm.Group controlId='confirmPassword'>
                      <InputBox
                        autoComplete='off'
                        type='password'
                        label='Confirm Password *'
                        name='confirmPassword'
                        tabIndex={8}
                      />
                    </BsForm.Group>
                  </Col>
                </Row>
                <BsForm.Group controlId='btnSubmit'>
                  <Button type='submit' block disabled={!(dirty && isValid) || isSubmitting} tabIndex={10}>
                    {isSubmitting ? <Spinner animation='border' size='sm' /> : 'Register'}
                  </Button>
                </BsForm.Group>
              </Form>
            )}
          </Formik>
          <div className='form-group text-center'>
            <span className='text-muted'>already have an account?</span> <Link to='/'>Login</Link>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default Registration
