import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Spinner, Table } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router'
import { Form, Formik } from 'formik'
import { Form as BsForm } from 'react-bootstrap'
import * as Yup from 'yup'
import InputBox from '../components/InputBox'
import axios from 'axios'
import toast from 'react-hot-toast'
import { VscLoading } from 'react-icons/vsc'
import XLSX from 'xlsx'

const Home = () => {
  const history = useHistory()
  const { state } = useLocation()
  const [Industries, setIndustries] = useState([])
  const [fileInputLabel, setFileInputLabel] = useState('Please select an excel file')
  const [FileData, setFileData] = useState([])
  const [Loading, setLoading] = useState(true)

  useEffect(() => {
    document.title = 'Plug & Play - Training and Plan Q&A'
    async function getAllIndustries() {
      if (state) {
        await axios({
          method: 'GET',
          baseURL: process.env.REACT_APP_API_URL,
          url: '/industries',
        })
          .then((response) => {
            setLoading(false)
            setIndustries(response.data)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error)
          })
      } else {
        toast.error('If you want to planing Q&A... then make sure you have an bot Theme setup first.')
        history.goBack()
      }
    }
    // this network request Checks if user has Access token or not. if your has token then redirect to the main page.
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/authcheck',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(() => {
        setLoading(false)
        getAllIndustries()
      })
      .catch((error) => {
        localStorage.clear()
        setLoading(false)
        window.location = '/'
        console.log(error)
      })

    return () => {
      setIndustries([])
    }
  }, [history, state])

  const initialValues = {
    botname: '',
    industry: '',
    data: [],
  }
  // validation
  const validationSchema = Yup.object().shape({
    botname: Yup.string().min(4).max(32).required().label('Bot Name'),
    industry: Yup.string().required().label('Industry'),
    data: Yup.array().min(1).required().label('Upload file'),
  })

  // This function for submit the details of bot like botname, industry name, and excel file data...
  const onSubmit = async (values, helpers) => {
    await axios({
      method: 'POST',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/createbot',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: {
        botname: values.botname,
        industry: values.industry,
        botthemeId: state.ThemeId,
        data: values.data,
      },
    })
      .then((response) => {
        helpers.resetForm()
        history.push({
          pathname: '/bot/test',
          state: { data: response.data },
        })
        toast.success('Bot information saved successfully! now you can customize your bot here :)', {
          duration: 10000,
        })
      })
      .catch((error) => {
        toast.error(error.response.data.Error)
      })
  }

  // this function covert excel file into json format and store in a variable.
  function handleChange(e, setFieldValue) {
    const file = e.target.files[0]
    setFileInputLabel(file.name)
    if (file && file[0]) {
      setFileData(file[0])
    }
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = (e) => {
      const bstr = e.target.result
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
      })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data = XLSX.utils.sheet_to_json(ws)
      setFileData(data)
      setFieldValue('data', data)
    }
    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }
  }

  return (
    <Container>
      {Loading && (
        <div className='h-100 d-flex justify-content-center align-items-center'>
          <VscLoading size='28' className='spin' />
          <div className='ml-2'>Loading...</div>
        </div>
      )}
      {!Loading && (
        <Row>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ isSubmitting, isValid, dirty, setFieldValue }) => (
                <Form>
                  <BsForm.Group controlId='botname'>
                    <InputBox autoComplete='off' type='text' label='Bot Name *' name='botname' tabIndex={1} />
                  </BsForm.Group>
                  <BsForm.Group controlId='industryId'>
                    <InputBox
                      as='select'
                      custom
                      label='List of Industrys *'
                      name='industry'
                      value={undefined}
                      disabled={Loading}
                      onChange={(e) => {
                        setFieldValue('industry', e.target.value)
                      }}
                      tabIndex={2}
                    >
                      <option label={Loading ? 'Loading...' : 'Select a industry'} defaultValue />
                      {Industries.map((industry, index) => (
                        <option key={index} value={industry.id} label={industry.name} />
                      ))}
                    </InputBox>
                  </BsForm.Group>
                  <BsForm.Group>
                    <Button
                      className='d-flex align-items-center'
                      block
                      as='a'
                      href={`${process.env.PUBLIC_URL}/Template.xlsx`}
                      variant='info'
                      download
                    >
                      <svg style={{ height: 16, width: 16 }} fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth={2}
                          d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4'
                        />
                      </svg>
                      <span className='mx-auto'>Click here to download an excel template</span>
                    </Button>
                  </BsForm.Group>
                  <BsForm.Group controlId='excelFile'>
                    <BsForm.Label>Upload file *</BsForm.Label>
                    <InputBox
                      file='true'
                      label={fileInputLabel}
                      name='data'
                      value={undefined}
                      accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                      onChange={(e) => {
                        handleChange(e, setFieldValue)
                      }}
                      tabIndex={3}
                    />
                  </BsForm.Group>
                  <BsForm.Group controlId='btnNext'>
                    <Button type='submit' block tabIndex={4} disabled={!(dirty && isValid) || isSubmitting}>
                      {isSubmitting ? <Spinner animation='border' size='sm' /> : 'Next'}
                    </Button>
                  </BsForm.Group>
                </Form>
              )}
            </Formik>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <div>
              <div className='m-0'>File Preview</div>
              <hr />
              {FileData.length >= 1 ? (
                <Table responsive striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Query</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {FileData.map((d, i) => (
                      <tr key={i}>
                        <th>{i + 1}</th>
                        <td>{d.Query}</td>
                        <td>{d.Answer}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div>
                  <div>file not yet upload!</div>
                  <div>to see a preview please upload a excel file </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Home
