import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { Container, Col, Row, Button, Spinner } from 'react-bootstrap'
import { IoClose, IoSend } from 'react-icons/io5'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import jwt_decode from 'jwt-decode'
import { VscLoading } from 'react-icons/vsc'

import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'
import 'codemirror/theme/neat.css'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/javascript/javascript.js'

import { UnControlled as CodeMirror } from 'react-codemirror2'
import toast from 'react-hot-toast'

const TestBot = () => {
  const history = useHistory()
  const { state } = useLocation()
  const [IsLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState({})
  const [BotData, setBotData] = useState({})
  const [Messages, setMessages] = useState([])
  const [botId, setBotId] = useState()

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView()

  useEffect(() => {
    document.title = 'Plug & Play - Test and Integrate Bot'
    const token = localStorage.getItem('token')
    if (token) {
      const user = jwt_decode(token)
      setUser(user)
    }
    // This function is use for get all the data from database like bot name, theme, title, subtitle, etc...
    function getBotDetails() {
      if (state) {
        axios({
          method: 'GET',
          baseURL: process.env.REACT_APP_API_URL,
          url: `/bot/${state && state.data.botid}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then((res) => {
            setIsLoading(false)
            setBotId(res.data.id)
            setBotData(res.data.bottheme)
          })
          .catch((error) => {
            setIsLoading(false)
            console.log(error)
          })
      } else {
        setIsLoading(false)
        toast.error('If you want to test the bot, then make sure you have an bot details with some Q&A.')
        history.goBack()
      }
    }
    // this network request Checks if user has Access token or not. if your has token then redirect to the main page.
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/authcheck',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(() => {
        setIsLoading(false)
        getBotDetails()
      })
      .catch((error) => {
        setIsLoading(false)
        localStorage.clear()
        window.location = '/'
        console.log(error)
      })

    return () => {
      setBotData({})
      setIsLoading(false)
    }
  }, [state, history])

  const initialValues = {
    query: '',
  }

  const validationSchema = Yup.object().shape({
    query: Yup.string().required().label('Query'),
  })
  // this submit function if fatch answer of the query user type their query and submit the data then in the response user get his answer
  const onSubmit = async (values, helpers) => {
    await axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: `/query?query=${values.query}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        setMessages(Messages.concat({ query: values.query, answer: response.data.answer }))
        helpers.resetForm()
        executeScroll()
      })
      .catch((error) => {
        setMessages(Messages.concat({ query: values.query, answer: error.response.data.error }))
        helpers.resetForm()
        executeScroll()
      })
  }

  return (
    <Container>
      {IsLoading && (
        <div className='h-100 d-flex justify-content-center align-items-center'>
          <VscLoading size='28' className='spin' />
          <div className='ml-2'>Loading...</div>
        </div>
      )}
      {!IsLoading && (
        <Row>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <div className='bot shadow-sm border'>
              <div
                style={{ backgroundColor: BotData.titlebarColor }}
                className='bot-header border-bottom px-4 py-3 d-flex align-items-center justify-content-between'
              >
                <div className='flex-fill text-break'>
                  <div style={{ color: BotData.titlebartextColor }} className='h5 m-0'>
                    {BotData.title}
                  </div>
                  <div style={{ color: BotData.subtitletextcolor }} className='small'>
                    {BotData.subtitle}
                  </div>
                </div>
                <Button variant='link'>
                  <IoClose size={20} color={BotData.sendbuttoniconColor} />
                </Button>
              </div>

              <div className='bot-body overflow-auto d-flex flex-column bg-light'>
                {Messages.length <= 0 && (
                  <h5 className='m-0 h-100 d-flex text-muted align-items-center justify-content-center'>
                    Test your bot here...
                  </h5>
                )}
                {Messages.map((message, index) => (
                  <div ref={myRef} key={index} className='d-flex flex-column'>
                    {message.query && (
                      <div className='d-flex justify-content-end'>
                        <QueryMessage color={BotData.usermessagetextColor} bgColor={BotData.usermessageColor}>
                          {message.query}
                        </QueryMessage>
                      </div>
                    )}
                    {message.answer && (
                      <div className='d-flex justify-content-start'>
                        <AnswerMessage color={BotData.botmessagetextColor} bgColor={BotData.botmessageColor}>
                          {message.answer}
                        </AnswerMessage>
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className='bot-footer border-top bg-white'>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                  {({ isSubmitting, isValid, dirty, handleChange, handleBlur, values }) => (
                    <Form className='d-flex flex-row align-items-center'>
                      <input
                        name='query'
                        id='query'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.query}
                        className='w-100 border-0 px-4 py-3 bg-transparent'
                        placeholder='Type a message...'
                        type='text'
                      />
                      <Button type='submit' variant='link' disabled={!(dirty && isValid) || isSubmitting}>
                        {isSubmitting ? (
                          <Spinner animation='border' size='sm' />
                        ) : (
                          <IoSend size={20} color={BotData.sendbuttoniconColor} />
                        )}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12} xs={12}>
            <h4>Bot integration</h4>
            <p className='text-muted'>copy & paste below code snippet to your html file.</p>
            <hr />
            <CodeMirror
              value={`<div id="bot" data-agent-id="${botId}" data-company-id="${user.company}"></div>\n<script src="https://plug-and-play-client.pages.dev/static/index.js" type="application/javascript"></script>`}
              options={{
                mode: 'xml',
                theme: 'material',
                lineNumbers: true,
                lineWrapping: true,
                readOnly: true,
                viewportMargin: true,
              }}
              onChange={(editor, value) => {
                // console.log('uncontrolled', { value })
              }}
            />
          </Col>
        </Row>
      )}
    </Container>
  )
}

const QueryMessage = styled.div`
  border-radius: 15px 15px 0px 15px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  text-align: right;
  padding: 10px;
  max-width: 70%;
  margin-bottom: 8px;
`
const AnswerMessage = styled.div`
  border-radius: 15px 15px 15px 0px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: 10px;
  max-width: 70%;
  margin-bottom: 8px;
`

export default TestBot
