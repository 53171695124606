import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import { Container, Button, Spinner, Row, Col } from 'react-bootstrap'
import { VscLoading } from 'react-icons/vsc'
import * as Yup from 'yup'
import { Form as BsForm } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import toast from 'react-hot-toast'
import InputBox from '../components/InputBox'

const Profile = () => {
  const [IsLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState({})

  useEffect(() => {
    document.title = 'Plug & Play - Profile'
    // get user details from api
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/user',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        setUser(response.data)
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error)
      })
    return () => {}
  }, [])

  const initialValues = {
    email: user.email,
    name: user.name,
    phone: user.phone,
    telephone: user.telephone,
  }
  // validations
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
    name: Yup.string().required().label('Name'),
    phone: Yup.string()
      .matches(/^(?:[+\d].*\d|\d)$/, 'The field should have digits only')
      .min(10)
      .max(10)
      .nullable()
      .label('Phone Number'),
    telephone: Yup.string()
      .matches(/^(?:[+\d].*\d|\d)$/, 'The field should have digits only')
      .required()
      .min(10)
      .label('Telephone Number'),
  })
  // update and user values
  const onSubmit = async (values, helpers) => {
    await axios({
      method: 'PUT',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/user',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: {
        email: values.email,
        name: values.name,
        phone: values.phone,
        telephone: values.telephone,
      },
    })
      .then(() => {
        helpers.resetForm()
        toast.success('Your details have been updated successfully!')
      })
      .catch((error) => {
        toast.error(error.response.data.error)
      })
  }

  return (
    <Container>
      {IsLoading && (
        <div className='h-100 d-flex justify-content-center align-items-center'>
          <VscLoading size='28' className='spin' />
          <div className='ml-2'>Loading...</div>
        </div>
      )}
      {!IsLoading && (
        <Fragment>
          <h5>Update your details</h5>
          <hr />
          <Row>
            <Col lg={6}>
              <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ isSubmitting, isValid, dirty }) => (
                  <Form>
                    <BsForm.Group controlId='email'>
                      <InputBox type='email' label='Email address *' name='email' />
                    </BsForm.Group>
                    <BsForm.Group controlId='name'>
                      <InputBox type='text' label='Name *' name='name' />
                    </BsForm.Group>
                    <BsForm.Group controlId='phone'>
                      <InputBox type='text' value={undefined} label='Phone number' name='phone' />
                    </BsForm.Group>
                    <BsForm.Group controlId='telephone'>
                      <InputBox type='tel' label='Telephone number *' name='telephone' />
                    </BsForm.Group>

                    <BsForm.Group controlId='btnSubmit'>
                      <Button type='submit' block disabled={!(dirty && isValid) || isSubmitting}>
                        {isSubmitting ? <Spinner animation='border' size='sm' /> : 'Update details'}
                      </Button>
                    </BsForm.Group>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Fragment>
      )}
    </Container>
  )
}

export default Profile
