import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import { Col, Container, Image, Modal, Row } from 'react-bootstrap'
import { VscLoading } from 'react-icons/vsc'

const Instruction = () => {
  const [Video, setVideo] = useState('')
  const [ModalTitle, setModalTitle] = useState('')
  const [IsLoading, setIsLoading] = useState(true)
  const [show, setShow] = useState(false)

  useEffect(() => {
    document.title = 'Plug & Play - Instruction'
    // this network request Checks if user has Access token or not. if your has token then redirect to the main page.
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/authcheck',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then((response) => {
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        localStorage.clear()
        window.location = '/'
        console.log(error)
      })
    return () => {}
  }, [])

  // this function is use for close the prompt
  const handleClose = () => {
    setModalTitle('')
    setVideo('')
    setShow(false)
  }
  // this function is use for open the prompt
  const handleShow = () => setShow(true)

  return (
    <Container>
      {IsLoading && (
        <div className='h-100 d-flex justify-content-center align-items-center'>
          <VscLoading size='28' className='spin' />
          <div className='ml-2'>Loading...</div>
        </div>
      )}
      {!IsLoading && (
        <Fragment>
          <h4>
            Welcome to <span className='text-primary'>Plug & Play</span> - Self Service Bot
          </h4>
          <p className='text-muted'>
            Grow your business with Conversational Artificial Intelligence.
            <br />
            Stay ahead of the curve by building intelligent, interactive and customised bots for your enterprise.
          </p>
          <hr />
          <Row>
            <Col lg={4} md={6} sm={12} xs={12}>
              <h5>1. Visualize bot</h5>
              <Image
                onClick={() => {
                  setModalTitle('Visualize bot')
                  setVideo(process.env.PUBLIC_URL + '/videos/video1.mp4')
                  handleShow()
                }}
                style={{ cursor: 'pointer' }}
                src={process.env.PUBLIC_URL + '/images/info1.jpg'}
                alt='Visualize bot'
                className='shadow-sm form-group'
                loading='lazy'
                thumbnail
                fluid
              />
              <p className='text-muted'>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil officiis minima aliquam itaque voluptate
                reiciendis?
              </p>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <h5>2. Training bot</h5>
              <Image
                onClick={() => {
                  setModalTitle('Training bot')
                  setVideo(process.env.PUBLIC_URL + '/videos/video2.mp4')
                  handleShow()
                }}
                style={{ cursor: 'pointer' }}
                src={process.env.PUBLIC_URL + '/images/info2.jpg'}
                alt='Training bot'
                className='shadow-sm form-group'
                loading='lazy'
                thumbnail
                fluid
              />
              <p className='text-muted'>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil officiis minima aliquam itaque voluptate
                reiciendis?
              </p>
            </Col>
            <Col lg={4} md={12} sm={12} xs={12}>
              <h5>3. Test & Integrate bot</h5>
              <Image
                onClick={() => {
                  setModalTitle('Test & Integrate bot')
                  setVideo(process.env.PUBLIC_URL + '/videos/video3.mp4')
                  handleShow()
                }}
                style={{ cursor: 'pointer' }}
                src={process.env.PUBLIC_URL + '/images/info3.jpg'}
                alt='Testing bot'
                className='shadow-sm form-group'
                loading='lazy'
                thumbnail
                fluid
              />
              <p className='text-muted'>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil officiis minima aliquam itaque voluptate
                reiciendis?
              </p>
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose} size='lg' centered backdrop='static' keyboard={false}>
            <Modal.Header closeButton>
              <Modal.Title>{ModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <video className='w-100' autoPlay muted controls loop>
                <source src={Video} type='video/mp4'></source>
              </video>
            </Modal.Body>
          </Modal>
        </Fragment>
      )}
    </Container>
  )
}

export default Instruction
