import React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'

// ----- Pages -----
import Home from './pages/Home'
import Login from './pages/Login'
import PasswordReset from './pages/PasswordReset'
import NewPassword from './pages/NewPassword'
import Registration from './pages/Registration'
import UserVerification from './pages/UserVerification'
import CreateBot from './pages/CreateBot'
import _404 from './pages/_404'
import MainLayout from './layouts/MainLayout'
import TestBot from './pages/TestBot'
import Instruction from './pages/Instruction'
import Profile from './pages/Profile'
import UsersList from './pages/admin/UsersList'
import ThemesList from './pages/admin/ThemesList'

const Routes = () => {
  return (
    <Router>
      <Toaster position='top-center' reverseOrder={false} />
      <MainLayout>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/password/reset' component={PasswordReset} />
          <Route exact path='/reset-password/:token' component={NewPassword} />
          <Route exact path='/registration' component={Registration} />
          <Route exact path='/verify-email/:token' component={UserVerification} />
          <Route exact path='/instruction' component={Instruction} />
          <Route exact path='/profile' component={Profile} />
          <Route exact path='/create/bot' component={CreateBot} />
          <Route exact path='/bot/test' component={TestBot} />
          <Route exact path='/training/bot' component={Home} />
          <Route exact path='/admin/users/list' component={UsersList} />
          <Route exact path='/admin/:name/themes' component={ThemesList} />
          <Route path='/404' component={_404} />
          <Redirect to='404' />
        </Switch>
      </MainLayout>
    </Router>
  )
}

export default Routes
