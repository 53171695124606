import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { Col, Container, Row, Button, Spinner } from 'react-bootstrap'
import { Form as BsForm } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { VscLoading } from 'react-icons/vsc'
import InputBox from '../components/InputBox'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import jwt_decode from 'jwt-decode'

const Login = () => {
  const history = useHistory()
  const [IsLoading, setIsLoading] = useState(true)

  useEffect(() => {
    // this network request Checks if user has Access token or not. if your has token then redirect to the main page.
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/authcheck',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(() => {
        setIsLoading(false)
        window.location = '/instruction'
      })
      .catch((error) => {
        setIsLoading(false)
        localStorage.clear()
        console.log(error)
      })
    return () => {}
  }, [history])

  const initialValues = {
    email: '',
    password: '',
  }
  // validation for user login
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
    password: Yup.string().required().label('Password'),
  })

  // This function for user login functionality user need to provide email and password.
  const onSubmit = async (values, helpers) => {
    await axios({
      method: 'POST',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/signin',
      data: {
        email: values.email,
        password: values.password,
      },
    })
      .then((response) => {
        console.log(response)
        helpers.resetForm()
        localStorage.setItem('token', response.data.token)
        const user = jwt_decode(localStorage.getItem('token'), { header: false })
        if (user.role === '2') {
          window.location = '/admin/users/list'
        } else {
          window.location = '/instruction'
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error)
      })
  }

  return (
    <Container>
      {/* Check if loading is true the show loading component */}
      {IsLoading && (
        <div className='h-100 d-flex justify-content-center align-items-center'>
          <VscLoading size='28' className='spin' />
          <div className='ml-2'>Loading...</div>
        </div>
      )}
      {/* Check if loading is false the show login form */}
      {!IsLoading && (
        <Row>
          <Col xl={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} sm={12} xs={12}>
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ isSubmitting, isValid, dirty }) => (
                <Form>
                  <BsForm.Group controlId='email'>
                    <InputBox autoComplete='off' type='email' label='Email address' name='email' tabIndex={1} />
                  </BsForm.Group>
                  <BsForm.Group controlId='password'>
                    <InputBox autoComplete='off' type='password' label='Password' name='password' tabIndex={2} />
                  </BsForm.Group>
                  <div className='form-group'>
                    <Link to='/password/reset'>Forgot password?</Link>
                  </div>
                  <div className='form-group'>
                    <Button type='submit' block tabIndex={3} disabled={!(dirty && isValid) || isSubmitting}>
                      {isSubmitting ? <Spinner animation='border' size='sm' /> : 'Login'}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <div className='form-group text-center'>
              <span className='text-muted'>Don't have an account?</span> <Link to='/registration'>Get access</Link>
            </div>
          </Col>
        </Row>
      )}
    </Container>
  )
}

export default Login
