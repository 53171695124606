import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form as BsForm, Spinner } from 'react-bootstrap'
import { useLocation, useHistory } from 'react-router-dom'
import BotUI from '../components/botUI'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import InputBox from '../components/InputBox'
import { VscLoading } from 'react-icons/vsc'
import toast from 'react-hot-toast'

const CreateBot = () => {
  const [IsLoading, setIsLoading] = useState(true)
  const { state } = useLocation()
  const history = useHistory()

  useEffect(() => {
    document.title = 'Plug & Play - Visualize Bot'
    // this network request Checks if user has Access token or not. if your has token then redirect to the main page.
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/authcheck',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(() => setIsLoading(false))
      .catch((error) => {
        localStorage.clear()
        window.location = '/'
        console.log(error)
      })

    return () => {}
  }, [history])

  const initialValues = {
    title: '',
    subtitle: '',
    titlebarColor: '#ffffff',
    titletextColor: '#1b1b1b',
    subtitletextcolor: '#b8b8b8',
    usermessageColor: '#2398f5',
    usermessagetextColor: '#f1f1f1',
    botmessageColor: '#e2e2e2',
    botmessagetextColor: '#1b1b1b',
    sendbuttoniconColor: '#2398f5',
  }
  // validation for user bot creation
  const validationSchema = Yup.object().shape({
    title: Yup.string().required().max(40).label('Title'),
    subtitle: Yup.string().required().max(50).label('Subtitle'),
    titlebarColor: Yup.string().label('Titlebar color'),
    titletextColor: Yup.string().label('Title text color'),
    subtitletextcolor: Yup.string().label('Subtitle text color'),
    usermessageColor: Yup.string().label('User message color'),
    botmessageColor: Yup.string().label('Bot message color'),
    usermessagetextColor: Yup.string().label('User message text color'),
    botmessagetextColor: Yup.string().label('Bot message text color'),
    sendbuttoniconColor: Yup.string().label('Send button icon color'),
  })
  // this submit function store bot theme into database
  const onSubmit = async (values, helpers) => {
    await axios({
      method: 'POST',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/bot-theme',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      data: {
        title: values.title,
        subtitle: values.subtitle,
        titlebarColor: values.titlebarColor,
        titlebartextColor: values.titletextColor,
        subtitletextcolor: values.subtitletextcolor,
        usermessageColor: values.usermessageColor,
        botmessageColor: values.botmessageColor,
        usermessagetextColor: values.usermessagetextColor,
        botmessagetextColor: values.botmessagetextColor,
        sendbuttoniconColor: values.sendbuttoniconColor,
      },
    })
      .then((response) => {
        toast('Your theme created successfully', {
          icon: '👏',
        })
        helpers.resetForm()
        history.push('/training/bot', { ThemeId: response.data.botthemeid })
      })
      .catch((error) => {
        toast.error('Something went wrong please try again!')
        if (error.response.data.error) {
          toast.error(error.response.data.error)
        }
      })
  }

  return (
    <Container>
      {IsLoading && (
        <div className='h-100 d-flex justify-content-center align-items-center'>
          <VscLoading size='28' className='spin' />
          <div className='ml-2'>Loading...</div>
        </div>
      )}
      {!IsLoading && (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ isSubmitting, isValid, dirty, values }) => (
            <Row>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form>
                  <Row>
                    <Col sm={6} xs={12}>
                      <BsForm.Group controlId='title'>
                        <InputBox
                          autoComplete='off'
                          type='text'
                          label='Enter title *'
                          placeholder='Ex: AHA Digital Assistant'
                          name='title'
                        />
                      </BsForm.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <BsForm.Group controlId='subtitle'>
                        <InputBox
                          autoComplete='off'
                          type='text'
                          label='Enter subtitle *'
                          placeholder='Ex: Virtual assistant'
                          name='subtitle'
                        />
                      </BsForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4} xs={12}>
                      <BsForm.Group controlId='titlebarColor'>
                        <InputBox autoComplete='off' type='color' label='Titlebar color' name='titlebarColor' />
                      </BsForm.Group>
                    </Col>
                    <Col sm={4} xs={12}>
                      <BsForm.Group controlId='titletextColor'>
                        <InputBox autoComplete='off' type='color' label='Title text color' name='titletextColor' />
                      </BsForm.Group>
                    </Col>
                    <Col sm={4} xs={12}>
                      <BsForm.Group controlId='subtitletextcolor'>
                        <InputBox autoComplete='off' type='color' label='Subtitle color' name='subtitletextcolor' />
                      </BsForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <BsForm.Group controlId='usermessageColor'>
                        <InputBox
                          autoComplete='off'
                          type='color'
                          label='User message bubble color'
                          name='usermessageColor'
                        />
                      </BsForm.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <BsForm.Group controlId='usermessagetextColor'>
                        <InputBox
                          autoComplete='off'
                          type='color'
                          label='User message text color'
                          name='usermessagetextColor'
                        />
                      </BsForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <BsForm.Group controlId='botmessageColor'>
                        <InputBox
                          autoComplete='off'
                          type='color'
                          label='Bot message bubble color'
                          name='botmessageColor'
                        />
                      </BsForm.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <BsForm.Group controlId='botmessagetextColor'>
                        <InputBox
                          autoComplete='off'
                          type='color'
                          label='Bot message text color'
                          name='botmessagetextColor'
                        />
                      </BsForm.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <BsForm.Group controlId='sendbuttoniconColor'>
                        <InputBox
                          autoComplete='off'
                          type='color'
                          label='Send button icon color'
                          name='sendbuttoniconColor'
                        />
                      </BsForm.Group>
                    </Col>
                  </Row>
                  <BsForm.Group>
                    <Button type='submit' block disabled={!(dirty && isValid) || isSubmitting}>
                      {isSubmitting ? <Spinner animation='border' size='sm' /> : 'Create theme'}
                    </Button>
                  </BsForm.Group>
                </Form>
              </Col>
              <Col xl={6} lg={6} md={12} sm={12} xs={12}>
                <BotUI
                  // state={state}
                  messages={state && state.data.data}
                  botTitle={values.title}
                  botSubtitle={values.subtitle}
                  titleBarColor={values.titlebarColor}
                  titleTextColor={values.titletextColor}
                  subtitleTextColor={values.subtitletextcolor}
                  userMessageColor={values.usermessageColor}
                  userMessageTextColor={values.usermessagetextColor}
                  botMessageColor={values.botmessageColor}
                  botMessageTextColor={values.botmessagetextColor}
                  SendButtonIconColor={values.sendbuttoniconColor}
                />
              </Col>
            </Row>
          )}
        </Formik>
      )}
    </Container>
  )
}

export default CreateBot
