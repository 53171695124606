import axios from 'axios'
import { Fragment, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { VscLoading } from 'react-icons/vsc'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useHistory, useParams } from 'react-router-dom'

const UserVerification = () => {
  const { token } = useParams()
  const [Loading, setLoading] = useState(true)
  const [Error, setError] = useState(false)

  const history = useHistory()

  useEffect(() => {
    // this axios request is for get token and verify the user
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: `/verify-email/${token}`,
    })
      .then(() => {
        history.push('/')
        toast.success('Your account has been verified successfully!')
      })
      .catch((error) => {
        setLoading(false)
        setError(true)
        toast.error(error.response.data.error)
      })
    return () => {}
  }, [history, token])

  return (
    <div className='h-100 d-flex justify-content-center'>
      {Loading ? (
        <div className='d-flex align-items-center'>
          <VscLoading size='28' className='spin' />
          <div className='ml-2'>Verifying your account...</div>
        </div>
      ) : (
        <Fragment>
          {Error && (
            <span className='d-flex align-items-center'>
              <AiOutlineInfoCircle size='28' color='#ff4b4b' />
              <div className='ml-2'>Your varification token is invalid.</div>
            </span>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default UserVerification
