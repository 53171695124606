import React from 'react'
import { IoClose, IoSend } from 'react-icons/io5'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

const BotUI = (props) => {
  // fake message array
  const Messages = [
    {
      query: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
    {
      query: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    },
  ]

  return (
    <div className='bot shadow-sm border'>
      <div
        style={{ backgroundColor: props.titleBarColor }}
        className='bot-header border-bottom px-4 py-3 d-flex align-items-center justify-content-between'
      >
        <div className='flex-fill text-break'>
          <div style={{ color: props.titleTextColor }} className='h5 m-0'>
            {props.botTitle ? props.botTitle : 'Enter you bot title'}
          </div>
          <div style={{ color: props.subtitleTextColor }} className='small'>
            {props.botSubtitle ? props.botSubtitle : 'Online'}
          </div>
        </div>
        <Button variant='link'>
          <IoClose size={20} color={props.SendButtonIconColor} />
        </Button>
      </div>

      <div className='bot-body overflow-auto d-flex flex-column bg-light'>
        {Messages.length <= 0 && (
          <h5 className='m-0 h-100 d-flex text-muted align-items-center justify-content-center'>
            Test your bot here...
          </h5>
        )}
        {Messages.map((message, index) => (
          <div key={index} className='d-flex flex-column'>
            {message.query && (
              <div className='d-flex justify-content-end'>
                <QueryMessage color={props.userMessageTextColor} bgColor={props.userMessageColor}>
                  {message.query}
                </QueryMessage>
              </div>
            )}
            {message.answer && (
              <div className='d-flex justify-content-start'>
                <AnswerMessage color={props.botMessageTextColor} bgColor={props.botMessageColor}>
                  {message.answer}
                </AnswerMessage>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className='bot-footer border-top bg-white'>
        <div className='d-flex flex-row align-items-center'>
          <input
            name='query'
            id='query'
            className='w-100 border-0 px-4 py-3 bg-transparent'
            placeholder='Type a message...'
            type='text'
          />
          <Button type='submit' variant='link'>
            <IoSend size={20} color={props.SendButtonIconColor} />
          </Button>
        </div>
      </div>
    </div>
  )
}

const QueryMessage = styled.div`
  border-radius: 15px 15px 0px 15px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  text-align: right;
  padding: 10px;
  max-width: 70%;
  margin-bottom: 8px;
`
const AnswerMessage = styled.div`
  border-radius: 15px 15px 15px 0px;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.color};
  padding: 10px;
  max-width: 70%;
  margin-bottom: 8px;
`

export default BotUI
