import axios from 'axios'
import { useEffect, useState } from 'react'
import { Button, Container, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const UsersList = () => {
  const [Page, setPage] = useState(1)
  const [Error, setError] = useState()
  const [Items, setItems] = useState([])

  useEffect(() => {
    // this network request Checks if user has Access token or not. if your has token then redirect to the main page.
    axios({
      method: 'GET',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/authcheck',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(() => {
        // get all users list
        axios({
          method: 'GET',
          baseURL: process.env.REACT_APP_API_URL,
          url: `/admin/users/${Page}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
          .then((response) => {
            setItems(response.data.items)
            console.log(response.data.items)
          })
          .catch((error) => {
            setError(error.response.data.error)
          })
      })
      .catch((error) => {
        window.location = '/'
        localStorage.clear()
        console.log(error)
      })
    return () => {
      setPage(1)
    }
  }, [Page])

  return (
    <Container>
      {Error && <h4>{Error}</h4>}
      {!Error && (
        <Table responsive striped bordered hover>
          <caption>List of users</caption>
          <thead>
            <tr>
              <th>#</th>
              <th>name</th>
              <th>email</th>
              <th>country</th>
              <th>company</th>
              <th>company</th>
              <th>phone</th>
              <th>telephone</th>
              <th>Bot</th>
            </tr>
          </thead>
          <tbody>
            {Items.map((item, index) => (
              <tr key={index}>
                <th className='align-middle'>{item.id}</th>
                <td className='align-middle'>{item.name}</td>
                <td className='align-middle'>{item.email}</td>
                <td className='align-middle'>{item.country}</td>
                <td className='align-middle'>{item.company}</td>
                <td className='align-middle'>{item.company}</td>
                <td className='align-middle'>{item.phone}</td>
                <td className='align-middle'>{item.telephone}</td>
                <td className='align-middle'>
                  <Button
                    as={Link}
                    to={{
                      pathname: `/admin/${item.name.toLowerCase()}/themes`,
                      state: { themes: item.themes },
                    }}
                    size='sm'
                  >
                    View Bots
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}

export default UsersList
