import { Container, Row, Col, Form as BsForm, Button, Spinner } from 'react-bootstrap'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import toast from 'react-hot-toast'
import InputBox from '../components/InputBox'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'

const PasswordReset = () => {
  const history = useHistory()

  const initialValues = {
    email: '',
  }
  // validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required().label('Email'),
  })
  // this submit function is for send an email for an forgot password
  const onSubmit = async (values, helpers) => {
    await axios({
      method: 'POST',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/forget-password',
      data: {
        email: values.email,
      },
    })
      .then((response) => {
        helpers.resetForm()
        history.push('/')
        toast.success('You will receive forget password link if your email exists in our database.', {
          duration: 10000,
        })
      })
      .catch((error) => {
        toast.error(error.response.data.error)
      })
  }

  return (
    <Container>
      <Row>
        <Col xl={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={12} xs={12}>
          <div className='mb-5 text-center'>
            <h5>Reset your password</h5>
            <div className='text-muted'>Enter your email and we'll send you a link to reset your password.</div>
          </div>

          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ isSubmitting, isValid, dirty }) => (
              <Form>
                <BsForm.Group controlId='email'>
                  <InputBox autoComplete='off' type='email' label='Email address' name='email' tabIndex={1} />
                </BsForm.Group>
                <BsForm.Group>
                  <Button type='submit' block tabIndex={3} disabled={!(dirty && isValid) || isSubmitting}>
                    {isSubmitting ? <Spinner animation='border' size='sm' /> : 'Reset your password'}
                  </Button>
                </BsForm.Group>
              </Form>
            )}
          </Formik>
          <div className='form-group text-center'>
            <span className='text-muted'>Don't have an account?</span> <Link to='/registration'>Get access</Link>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default PasswordReset
