import React from 'react'
import { Container } from 'react-bootstrap'

const _404 = () => {
  return (
    <Container className='d-flex flex-column align-items-center justify-content-center'>
      <h1>404</h1>
      <p className='text-muted'>The page you are looking for isn’t here :(</p>
    </Container>
  )
}

export default _404
