import React, { Fragment, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { Button, Container, ListGroup, Nav, Navbar } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'

const MainLayout = ({ children }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    function getuser() {
      if (localStorage.getItem('token') === null) {
        localStorage.removeItem('token')
      } else {
        setUser(jwt_decode(localStorage.getItem('token'), { header: false }))
      }
    }
    getuser()
    return () => {
      setUser(null)
    }
  }, [])

  const handleClick = (e) => {
    e.preventDefault()
    localStorage.clear()
    window.location = '/'
  }

  return (
    <div className='d-flex' id='wrapper'>
      <div className='min-vh-100 bg-dark' id='sidebar-wrapper'>
        <Navbar bg='dark' variant='dark'>
          <Navbar.Brand href='/'>Plug & Play</Navbar.Brand>
        </Navbar>
        {user ? (
          <ListGroup variant='flush'>
            <Fragment>
              {user.role === '2' ? (
                <ListGroup.Item action variant='dark' as={NavLink} exact to='/admin/users/list'>
                  User List
                </ListGroup.Item>
              ) : (
                <Fragment>
                  <ListGroup.Item action variant='dark' as={NavLink} exact to='/instruction'>
                    Instruction
                  </ListGroup.Item>
                  <ListGroup.Item action variant='dark' as={NavLink} exact to='/create/bot'>
                    Visualize Bot
                  </ListGroup.Item>
                  <ListGroup.Item action variant='dark' as={NavLink} exact to='/training/bot'>
                    Training & Plan QnA
                  </ListGroup.Item>
                  <ListGroup.Item action variant='dark' as={NavLink} exact to='/bot/test'>
                    Test & Integrate Bot
                  </ListGroup.Item>
                  <ListGroup.Item action variant='dark' as={NavLink} exact to='/profile'>
                    User Profile
                  </ListGroup.Item>
                </Fragment>
              )}
              <ListGroup.Item action variant='danger' onClick={(e) => handleClick(e)}>
                Logout
              </ListGroup.Item>
            </Fragment>
          </ListGroup>
        ) : (
          <ListGroup variant='flush'>
            <ListGroup.Item action variant='dark' as={NavLink} exact to='/'>
              Login
            </ListGroup.Item>
            <ListGroup.Item action variant='dark' as={NavLink} exact to='/registration'>
              Registration
            </ListGroup.Item>
          </ListGroup>
        )}
      </div>

      <div id='page-content-wrapper'>
        <Navbar className='mb-4' bg='dark' variant='dark'>
          <Container>
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='mr-auto'>
                <Button variant='outline-dark' id='sidebarToggle'>
                  <span className='navbar-toggler-icon'></span>
                </Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className='container-fluid'>{children}</div>
      </div>
    </div>
  )
}

export default MainLayout
