import { Fragment, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import BotUI from '../../components/botUI'

const ThemesList = () => {
  const { state } = useLocation()
  console.log(state)
  const [Error, setError] = useState()
  const [Themes, setThemes] = useState([])

  useEffect(() => {
    if (state && state.themes.length <= 0) {
      setError('No themes found!')
    } else {
      setThemes(state.themes)
    }
    return () => {}
  }, [state])

  return (
    <Container>
      {Error && <h4>{Error}</h4>}
      {!Error && (
        <Fragment>
          <Row>
            {Themes.map((theme, index) => {
              return (
                <Col key={index} xl={4} lg={6} md={6} sm={12} xs={12}>
                  <div className='form-group h-100'>
                    <BotUI
                      botTitle={theme.title}
                      botSubtitle={theme.subtitle}
                      titleBarColor={theme.titlebarColor}
                      titleTextColor={theme.titlebartextColor}
                      subtitleTextColor={theme.subtitletextcolor}
                      userMessageColor={theme.usermessageColor}
                      userMessageTextColor={theme.usermessagetextColor}
                      botMessageColor={theme.botmessageColor}
                      botMessageTextColor={theme.botmessagetextColor}
                      SendButtonIconColor={theme.sendbuttoniconColor}
                    />
                  </div>
                </Col>
              )
            })}
          </Row>
        </Fragment>
      )}
    </Container>
  )
}

export default ThemesList
