import React, { Fragment } from 'react'
import { Form } from 'react-bootstrap'
import { useField } from 'formik'

const InputBox = ({ file, className, label, ...props }) => {
  const [field, meta] = useField(props)
  return (
    <Fragment>
      {!file ? (
        <Fragment>
          <Form.Label>{label}</Form.Label>
          <Form.Control isInvalid={meta.touched && meta.error} {...field} {...props} />
          <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
        </Fragment>
      ) : (
        <Fragment>
          <Form.File custom>
            <Form.File.Input isInvalid={meta.touched && meta.error} {...field} {...props} />
            <Form.File.Label>{label}</Form.File.Label>
            <Form.Control.Feedback type='invalid'>{meta.error}</Form.Control.Feedback>
          </Form.File>
        </Fragment>
      )}
    </Fragment>
  )
}

export default InputBox
